import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavBreadService {
  breadcrumb = new Subject();
  constructor() { }

}
