import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { NavbarService } from 'app/layout/components/navbar/navbar.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  private uri = environment.apiUrl;
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService, private nav: NavbarService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param user
   * 
   * @returns user
   */
  login(user: any) {
    return this._http
      .post<any>(`${this.uri}/auth/login`, user)
      .pipe(
        map(user => {
          const { data, error } = user;          
          if (!error && user.code == 'S000') {
            data.token = data.access_token;
            localStorage.setItem('currentUser', JSON.stringify(data));
            this._toastrService.success(
              'You have successfully logged in as an user to Rorktenh. Now you can start to explore. Enjoy! 🎉',
              '👋 Welcome, to roktenh!',
              { toastClass: 'toast ngx-toastr', closeButton: true }
            );
            this.currentUserSubject.next(data);
          }
          return data;
        })
      );
  }
  onGetOtp(phone, action) {
    let params = new HttpParams();
    params = params.append('phone', phone);
    params = params.append('action', action);
    // return this._http.post<any>(`http://localhost:3000/auth/client/send_otp`, null,
    //   { params: params }
    // );
    return this._http.post<any>(`${this.uri}/auth/client/send_otp`, null,
      { params: params }
    );
  }
  onResetPassword(body) {
    return this._http.put(`${this.uri}/auth/client/reset_password`, body);
  }
  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
    this.nav.breadcrumb.next(null);
  }
}
