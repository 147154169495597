<form class="upload-file">
  <div class="upload-body" [ngClass]="{'border-warning': allowFileMessage}">
    <label for="fileUpload" class="upload-icon">
      <i class="fa fa-upload"></i>
      <h3>Upload file {{ fileUpload?.files[0]?.size | formatFileSize }}</h3>
      <small class="text text-warning">{{ allowFileMessage }}</small>
      <input type="file" (change)="onFileChange(fileUpload.files)" [accept]="allowExtension" #fileUpload id="fileUpload"
        class="hidden">
    </label>
  </div>
</form>
<div class="d-flex justify-content-end mt-2">
  <button class="btn btn-dark mr-1" (click)="onCancel()">Close</button>
  <button class="btn btn-primary" [disabled]="!allowFile" (click)="onSubmit()">Confirm</button>
</div>