import { AuthenticationService } from 'app/auth/service';
import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {
  constructor(
    public http: HttpClient,
    private _authenticationService: AuthenticationService,
  ) { }
  transform(url: string) {
    let data = '';
    const currentUser = this._authenticationService.currentUserValue;
    this.http.get(`${url}`, { headers: { Authorization: `Bearer ${currentUser.token}` } }).subscribe((res: any) => {
      console.log('image', res);
      data = res;
    })
    return data;
  }
}
