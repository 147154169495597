import { requestBody } from '@core/services/convert.service';
import { RefreshToken } from './../models/refresh-token.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models';

@Injectable({ providedIn: 'root' })
export class UserService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) { }
  onRequestToken(refresh_token) {
    const data: RefreshToken = {
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      refresh_token
    }
    const res = requestBody(data);
    this._http.post<any>(`${environment.apiUrl}/auth/refresh_token`, res).subscribe((res: any) => {
      console.log(res);
    });
  }
  /**
   * Get all users
   */
  getAll() {
    return this._http.get<User[]>(`${environment.apiUrl}/users`);
  }

  /**
   * Get user by id
   */
  getById(id: number) {
    return this._http.get<User>(`${environment.apiUrl}/users/${id}`);
  }
}
