<!-- app-breadcrumb start -->
<h2 class="my-1 d-sm-none title-bar-xs">{{ title }}</h2>
<div class="breadcrumb-wrapper d-flex pb-1" *ngIf="breadcrumb?.links?.length>0">
  <ol class="breadcrumb pl-0" [ngClass]="{
      'breadcrumb-slash': breadcrumb?.type == 'slash',
      'breadcrumb-dots': breadcrumb?.type == 'dots',
      'breadcrumb-dashes': breadcrumb?.type == 'dashes',
      'breadcrumb-pipes': breadcrumb?.type == 'pipes',
      'breadcrumb-chevron': breadcrumb?.type == 'chevron'
    }">
    <li class="breadcrumb-item">
      <span><i data-feather="home"></i></span>
    </li>
    <li class="breadcrumb-item" *ngFor="let link of breadcrumb?.links">
      <a *ngIf="link.isLink" routerLink="{{ link.link }}"> {{
        link.name }}</a>
      <span *ngIf="!link.isLink">{{ link.name }}</span>
    </li>
  </ol>
</div>
<!-- app-breadcrumb end -->