import { FormatFileSizePipe } from './../../utilities/pipes/formatFileSize.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileComponent } from './upload-file.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  declarations: [UploadFileComponent, FormatFileSizePipe],
  exports: [UploadFileComponent]
})
export class UploadFileModule { }
