import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  allowFileMessage: string;
  allowFile: File;
  @Input('allowExtension') allowExtension: Array<any> = [];
  @Output('onSubmit') onSubmitUpload = new EventEmitter<File>();
  @Output('onCancel') onCancelForm = new EventEmitter();
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
  }
  onFileChange(files: File[]) {
    //for config data is allow extension form parent component ex:['.xlsb', '.xls', '.xlsx', '.xlsm']

    for (let i = 0; i < files.length; i++) {
      const extension = files[i].name.slice(files[i].name.lastIndexOf('.'), files[i].name.length);
      if (this.allowExtension.indexOf(extension) !== -1) {
        this.allowFile = files[i];
        this.allowFileMessage = null;
      } else {
        this.allowFileMessage = `File ${extension} extension not allow to upload.`;
        this.allowFile = null;
      }
    }
  }
  onSubmit() {
    this.onSubmitUpload.emit(this.allowFile)
  }
  onCancel() {
    this.onCancelForm.emit()
  }
}
