import { Breadcrumb } from './../content-header/breadcrumb/breadcrumb.component';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  titleSubject = new Subject();
  breadcrumb = new Subject();
  itemsHandler = this.breadcrumb.asObservable();
  title = this.titleSubject.asObservable();
  exportSubject = new Subject();
  constructor() {

  }
  setItems(items: any) {
    this.breadcrumb.next(items);
  }
  setTitle(title: any) {
    this.titleSubject.next(title);
  }
}
