import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
// routing
const routes: Routes = [
  {
    path: "user",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
  },
  {
    path: "store",
    loadChildren: () => import("./store/store.module").then((m) => m.StoreModule),
  },
  {
    path: "store-subscription",
    loadChildren: () => import("./store/st-subscription/st-subscription.module").then(m => m.StSubscriptionModule)
  },
  {
    path: "client",
    loadChildren: () =>
      import("./client/client.module").then((m) => m.ClientModule),
  },
  {
    path: "category",
    loadChildren: () =>
      import("./category/category.module").then((m) => m.CategoryModule),
  },
  {
    path: "attribute",
    loadChildren: () =>
      import("./attribute/attribute.module").then((m) => m.AttributeModule),
  },
  {
    path: "subscription",
    loadChildren: () =>
      import("./subscription/subscription.module").then((m) => m.SubscriptionModule),
  },
  { path: 'store-management/:storeId', 
  loadChildren: () => 
    import('./store-management/store-management.module').then(m => m.StoreManagementModule) },
];

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
]);

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ]
})
export class AppsModule { }
