import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavbarService } from './../../navbar/navbar.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

// Breadcrumb component interface
export interface Breadcrumb {
  type?: string;
  links?: Array<{
    name: string;
    isLink: boolean;
    link?: string;
  }>;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  // input variable
  @Input() breadcrumb: Breadcrumb;
  title: string;
  constructor(public navBarService: NavbarService) {
    this.navBarService.breadcrumb.subscribe((breadcrumb: Breadcrumb) => {
      this.breadcrumb = breadcrumb;
    });
    this.navBarService.title.subscribe((title: string) => {
      this.title = title;
    })
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    // concatenate default properties with passed properties
  }
}
