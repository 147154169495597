export enum Status {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DISABLE = "DISABLE",
  ENABLE = "ENABLE",
  RESET_PASSWORD = "RESET_PASSWORD",
  REGISTER = "REGISTER"
}
export enum FilesType {
  NID = "NID",
  PASSPORT = "PASSPORT",
  SELFIE = "SELFIE",
  PROFILE = "PROFILE"
}
export enum DROPDOWN {
  STATUS = 'STATUS',
  ROLE = 'ROLE',
  KYC_STATUS = 'KYC_STATUS',
  GENDER = 'GENDER',
  DOCUMENT = 'DOCUMENT',
  STORE_CATEGORY = 'STORE_CATEGORY',
  PAYMENT = 'PAYMENT'
}