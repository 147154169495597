import { CoreMenu } from "@core/types";
export const menu: CoreMenu[] = [
  // Dashboard
  // {
  //   id: "dashboard",
  //   title: "Dashboard",
  //   translate: "MENU.DASHBOARD.COLLAPSIBLE",
  //   type: "item",
  //   icon: "home",
  //   url: "dashboard/ecommerce",
  // },
  // store and subscription
  {
    id: "store_subscripton",
    type: "section",
    title: "Store & Subscription",
    translate: "MENU.APPS.SECTION",
    icon: "package",
    role: ['STORE'],
    children: [
      {
        id: "store_vs_subscription",
        title: "Store & Subscription",
        translate: "MENU.APPS.STORE.COLLAPSIBLE",
        type: "collapsible",
        icon: "shopping-bag",
        role: ['STORE'],
        children: [
          {
            id: 'store',
            title: 'All Store',
            translate: 'MENU.APPS.STORE.COLLAPSIBLE',
            type: 'item',
            icon: 'circle',
            activeRoute: "/apps/store",
            url: "apps/store/store-list",
          },
          // {
          //   id: 'store_subscription',
          //   title: 'All Subscription',
          //   translate: 'MENU.APPS.STORE.COLLAPSIBLE',
          //   type: 'item',
          //   icon: 'circle',
          //   url: 'apps/store-subscription/list'
          // }
        ]
      }
    ]
  },
  // Apps & Pages
  {

    id: "apps",
    type: "section",
    title: "Apps & Pages",
    translate: "MENU.APPS.SECTION",
    icon: "package",
    role: ["ADMIN", 'DEVELOPER'],
    children: [
      {
        role: ["ADMIN", 'DEVELOPER'],
        id: "user",
        title: "User Management",
        translate: "MENU.APPS.USER.COLLAPSIBLE",
        type: "item",
        icon: "user",
        activeRoute: "/apps/user",
        url: "apps/user/user-list",
      },

      // {
      //   id: "store_mg",
      //   title: "Store Management",
      //   translate: "MENU.APPS.STORE.COLLAPSIBLE",
      //   type: "item",
      //   icon: "shopping-bag",
      //   activeRoute: "/apps/user",
      //   url: "apps/user/user-list",
      // },
      {
        role: ["ADMIN", 'DEVELOPER'],
        id: "client",
        title: "Client Management",
        translate: "MENU.APPS.USER.COLLAPSIBLE",
        type: "item",
        icon: "user",
        activeRoute: "/apps/client",
        url: "apps/client/client-list",
      },
      {
        role: ["ADMIN", 'DEVELOPER'],
        id: "category",
        title: "Category Management",
        translate: "MENU.APPS.USER.COLLAPSIBLE",
        type: "item",
        icon: "box",
        activeRoute: "/apps/category",
        url: "apps/category/category-list",
      },
      {
        role: ["ADMIN", 'DEVELOPER'],
        id: "attribute",
        title: "Attributed Management",
        translate: "MENU.APPS.USER.COLLAPSIBLE",
        type: "item",
        icon: "bookmark",
        activeRoute: "/apps/attribute",
        url: "apps/attribute/attribute-list",
      },
      {
        role: ["ADMIN", 'DEVELOPER'],
        id: "subscription",
        title: "Subcription Management",
        translate: "MENU.APPS.USER.COLLAPSIBLE",
        type: "item",
        icon: "send",
        activeRoute: "/apps/subscription",
        url: "apps/subscription/subscription-list",
      },
      {
        role: ["ADMIN", 'DEVELOPER'],
        id: "product",
        title: "Product Management",
        translate: "",
        type: "item",
        icon: "shopping-cart",
        activeRoute: "/apps/store-management",
        url: "apps/store-management/11/product-management/list",
      },
    ],
  },
  {
    id: "store",
    type: "section",
    title: "General",
    translate: "MENU.APPS.SECTION",
    icon: "package",
    role: ["ADMIN", 'DEVELOPER'],
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        translate: "",
        type: "item",
        icon: "home",
        url: "apps/store-management/11/product-management/list",
      },
      // {
      //   id: "store_and_description",
      //   title: "Store & Subscription",
      //   translate: "",
      //   type: "item",
      //   icon: "home",
      // },
      {
        id: "store_and_description",
        title: "Store & Subscription",
        translate: "",
        type: "collapsible",
        icon: "home",
        children: [
          {
            id: 'all_store',
            title: 'All Stores',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: "apps/store-management/11/product-management/list",
          },
          {
            id: 'all_subscription',
            title: 'All Subscription',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          }
        ]
      },
      {
        id: "pos_management",
        title: "POS Management",
        translate: "",
        type: "collapsible",
        icon: "home",
        children: [
          {
            id: 'pos_sell_management',
            title: 'POS Sell Management',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          },
          {
            id: 'pos_balance',
            title: 'POS Balance',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          },
          {
            id: 'pos_setting',
            title: 'POS Setting',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          }
        ]
      },
      {
        id: "store_management",
        title: "Store Management",
        translate: "",
        type: "collapsible",
        icon: "home",
        children: [
          {
            id: 'store_setting',
            title: 'POS Sell Management',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          },
          {
            id: 'category_management',
            title: 'Category Management',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          },
          {
            id: 'attribute_management',
            title: 'Attribute Management',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          },
          {
            id: 'product_management',
            title: 'Product Management',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          },
          {
            id: 'product_return',
            title: 'Product Return',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          },
          {
            id: 'sale_order',
            title: 'Sale Order',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          },
          {
            id: 'coupon_management',
            title: 'Coupon Management',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          },
          {
            id: 'report',
            title: 'Report',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          },
          {
            id: 'page_management',
            title: 'Page Management',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          }
        ]
      },
      {
        id: "transactions_and_billing",
        title: "Transactions & Billing",
        translate: "",
        type: "collapsible",
        icon: "shopping-cart",
        children: [
          {
            id: 'billing_history',
            title: 'Billing History',
            translate: 'MENU.CM.CHARTS.CHARTJS',
            type: 'item',
            icon: 'circle',
            // url: "apps/store-management/11/product-management/list",
          },
          {
            id: 'settlement',
            title: 'Settlement',
            translate: 'MENU.CM.CHARTS.CHARTJS',
            type: 'item',
            icon: 'circle',
            // url: "apps/store-management/11/product-management/list",
          },
        ]
      },
      {
        // role: ["ADMIN", 'DEVELOPER'],
        id: "product",
        title: "Product Management",
        translate: "",
        type: "collapsible",
        icon: "shopping-cart",
        // activeRoute: "/apps/store-management",
        // url: "apps/store-management/11/product-management/list",
        children: [
          {
            id: 'chartJs',
            title: 'ChartJS',
            translate: 'MENU.CM.CHARTS.CHARTJS',
            type: 'item',
            icon: 'circle',
            // url: "apps/store-management/11/product-management/list",
          },
          {
            id: 'chartJs',
            title: 'ChartJS',
            translate: 'MENU.CM.CHARTS.CHARTJS',
            type: 'item',
            icon: 'circle',
            // url: "apps/store-management/11/product-management/list",
          }
        ]
      },
    ]
  },
  {
    id: "setting",
    type: "section",
    title: "Setting",
    // translate: "MENU.APPS.SECTION",
    icon: "package",
    role: ["ADMIN", 'DEVELOPER'],
    children: [
      {
        id: "user_management",
        title: "User Management",
        translate: "",
        type: "item",
        icon: "home",
        url: '#',
      },
      {
        id: "account_management",
        title: "Account Management",
        translate: "",
        type: "collapsible",
        icon: "home",
        children: [
          {
            id: 'all_store',
            title: 'All Stores',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          },
          {
            id: 'all_subscription',
            title: 'All Subscription',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          }
        ]
      },
      {
        id: "delivery_network",
        title: "Delivery Network",
        translate: "",
        type: "collapsible",
        icon: "home",
        children: [
          {
            id: 'all_store',
            title: 'All Stores',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          },
          {
            id: 'all_subscription',
            title: 'All Subscription',
            translate: '',
            type: 'item',
            icon: 'circle',
            url: '#',
          }
        ]
      }
    ]
  }
];
