import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        console.log('error intercetor',err)
        if (['OA2400', 'E401', 'A401'].indexOf(err.error.code) !== -1) {
          this._authenticationService.logout();
          this._router.navigate(['/pages/authentication/login']);
        }
        if ([401, 403, 'OA2401'].indexOf(err.error.code) !== -1) {
          this._authenticationService.logout();
          this._router.navigate(['/pages/authentication/login']);
        }
        // throwError
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
