import { Status } from './../enums/status.enum';
import { IBody } from './../../app/main/apps/base/body.interface';
import { Injectable } from '@angular/core';
import moment from 'moment';
import JSEncrypt from 'jsencrypt';
import * as Forge from 'node-forge';
import { ToastrService } from 'ngx-toastr';
const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlecCLb6m4wSgwrL0Hk0i
ZaRtg/jIPcLNxOv5tdSuu7Z+0+ng2ZmvECMumFr+oiUHYNbfCuWJsCUEdyVrlGNn
jkdfgoIH2y96ysf1/NfjKnnNT2zM/FW3UsxOAeV4mrAyZINfu1T7GaUtC6cY1JnY
xB3pi3EWQfln9T5HhLjj9iNG0Kw7EBZgCXpHTieSeL/GmyHgOjQrmKgWCMIFNq8B
1hf39fzc/hVEfqq2TvSjsD+I3DbPwlT4fe71eRagawwOtt5HhngIsEbpC1VKu9FP
JUSuZSdrA6VQ8pA2yfnb4zQROLKJWf+qheeHZHnHlho9mD6zJkGMZVqD6eiIXFa4
RwIDAQAB
-----END PUBLIC KEY-----`;
@Injectable({
  providedIn: 'root'
})
export class ConvertService {
  constructor(private toastr:ToastrService) { }

  onMessage(type: 'error' | 'success', message) {
    if(type === 'success'){
      this.toastr.success(message,'Success!',{
        toastClass: 'toast ngx-toastr',
        closeButton: true
      })
    }else if(type === 'error'){
      this.toastr.error(message,'Error!',{
        toastClass: 'toast ngx-toastr',
        closeButton: true
      })
    }
  }
}

export function convertToUid() {
  return moment().format('YYYYMMDDhmss');
}
export function requestBody(value) {
  const data: IBody = {
    data: value,
    uuid: convertToUid()
  }
  return data;
}
export function convertRSA(value: string): string {
  const crypt = new JSEncrypt();
  crypt.setPublicKey(publicKey);
  const rsaCrypt = crypt.encrypt(value)
  return rsaCrypt.toString();
}
export function convertBase64(value: string) {
  // return value.toString('base64');
}
export function statusS000(code: string) {
  return code === 'S000';
}
export function encryptWithPublicKey(valueToEncrypt: string): string {
  const rsa = Forge.pki.publicKeyFromPem(publicKey);
  return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
}
export function compareStatus(value) {
  var status;
  switch (value) {
    case 'enable_value':
      status = Status.ACTIVE;
      break;
    case 'disable_value':
      status = Status.INACTIVE;
      break;
    case 'enable':
      status = Status.ACTIVE;
      break;
    case 'disable':
      status = Status.INACTIVE;
      break;
  }
  return status;
}