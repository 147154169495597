// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const apiUrl = 'https://api.roktenh.com';
// const apiUrl = 'http://localhost:3005';
// const apiUrl = 'http://localhost:3000';
export const environment = {
  production: false,
  hmr: false,
  apiUrl,
  checkVersion: '0.1.0',
  baseStorage: apiUrl,
  client_id: 'roktenh-web',
  client_secret: '1234',
  TAILWIND_MODE: 'build',
  basic: 'Basic cm9rdGVuaC13ZWI6aUlSU2s0S1BWUjQ1L1dhZHdnY2laK1lHdWdyWTFpSkpWeDRLc2h4MVJ0aERwd1c1VTNnc0h2bDZjUVludEZLVHFGY25pa2pkQ3hkWjBvYkE4NWNZVEQzUlZQMDlVREZDMDF6NzdDcDlxNzhlUW8yN1dac2JsbXBKVmhtV2MraUE3bGI0N2JRN3ZUWnQzcStqQWJ6S1VTVWN0dnhEYXNBK1NrOVdGSWdJL2VFdHo3dDRWNGhpaHlhYW9ieExkRnlVMVBoaUxDZ3JhTnlkci94RWUycU9oTnJWdU1CM05UU1YzMVRtZVFFRUxmMHEreTBvWHdlNkhEZ0RLUE83YXptSlR6L1JvKzFFaTNVWkhnTk9tN0g1YSt1UFVIZGhUc0NoVHF1cncwTDR3a2JNSTREY3llS25hWGNzbU90VFdxM0RjT3hIQ1JpVGtrR1NJcG95cVRPRjJRPT0='
};