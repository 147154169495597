import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class LoadingService {
  showLoading = new Subject()
  loading = this.showLoading.asObservable();
  constructor() { }
  onLoading(isTrue: boolean) {
    this.showLoading.next(isTrue)
  }
}
