import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { UserService } from './../service/user.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';
import { catchError, finalize } from 'rxjs/operators';
import { LoadingService } from '@core/services/loading.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private userService: UserService,
    private loading: LoadingService
  ) {
    // this.userService.onRequestToken();
  }

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this._authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const authorization = request.headers.get('Authorization');
    if (isLoggedIn && isApiUrl) {
      this.loading.onLoading(true);
      request = request.clone({
        setHeaders: {
          Authorization: authorization ? authorization : `Bearer ${currentUser.token}`
        }
      });

    }
    // else if(isNotNullOrUndefined(authorization)) {
    //   console.log('condi', isNotNullOrUndefined(authorization), authorization);
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: environment.basic
    //     }
    //   })
    // }
    return next.handle(request).pipe(finalize(() => { this.loading.onLoading(false) }));
  }
}
