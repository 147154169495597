<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="#" target="_blank">{{ title }}</a>
  </span>
  <span class="float-md-right d-none d-md-block">
    Hand-crafted by <a href="https://bongkert.com/" target="_blank" rel="noopener noreferrer">Bongkert</a>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
<div class="buy-now">
  <a href="" target="_blank" class="btn btn-danger" *ngIf="coreConfig.layout.buyNow">Buy Now</a>
</div>